<template>
  <content-layout
    :loading="loading"
    :breadcrumbs-items="breadcrumbsItems"
    :fluid="false"
  >
    <template #toolbar-actions v-if="order">
      <div class="d-flex align-center" style="gap: 25px">
        <cz-button
          outlined
          :title="$t('customer.orders.orderApprovalHistoryShort')"
          @click="onShowOrderApprovalHistory"
          :icon-src="mdiHistory"
          v-if="id !== 'new'"
        />
        <v-menu
          offset-y
          :nudge-bottom="10"
          :min-width="180"
          v-if="
            canCloseOrder ||
            canCancelOrder ||
            (order.creationType === 'manual' && id !== 'new')
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <cz-button
              v-bind="attrs"
              v-on="on"
              outlined
              :icon-src="mdiDotsHorizontalCircleOutline"
              :title="$t('common.moreActions')"
            />
          </template>
          <v-list>
            <v-list-item v-if="canCancelOrder" @click="onCancelOrder">
              <v-list-item-icon class="ml-2">
                <cz-icon :src="mdiCancel" color="primary" :size="22" />
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t('customer.orders.cancelOrder') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="canCloseOrder" @click="onCloseOrder">
              <v-list-item-icon class="ml-2">
                <cz-icon
                  :src="mdiCloseCircleOutline"
                  color="primary"
                  :size="22"
                />
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t('customer.orders.closeOrder') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="order.creationType === 'manual'"
              @click="onShowOrderApprovalHistory"
            >
              <v-list-item-icon class="ml-2">
                <cz-icon :src="mdiHistory" color="primary" :size="22" />
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t('customer.orders.orderApprovalHistory') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>

    <router-view :value="order" @order-saved="onOrderSaved" />

    <cancel-order-dialog
      v-if="cancelOrderDialog.show"
      v-model="cancelOrderDialog.show"
      v-bind="cancelOrderDialog"
      @close="cancelOrderDialog.show = false"
      @cancel="cancelOrder"
    />

    <close-order-dialog
      v-if="closeOrderDialog.show"
      v-model="closeOrderDialog.show"
      v-bind="closeOrderDialog"
      @close="closeOrderDialog.show = false"
      @done="closeOrder"
    />

    <order-approval-history-dialog
      v-model="orderApprovalHistoryDialog.show"
      v-bind="orderApprovalHistoryDialog"
      @close="orderApprovalHistoryDialog.show = false"
    />
  </content-layout>
</template>

<script>
import ContentLayout from '@/layouts/ContentLayout.vue';
import { getOrders, updateOrder, getOrderApprovalHistory } from '@/core/api';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { CzIcon, CzButton } from '@/components';
import { format } from 'date-fns';
import { typesMixin } from '@/shared/mixins';
import {
  mdiInformation,
  mdiInformationOutline,
  mdiHistory,
  mdiPencil,
  mdiEmailFastOutline,
  mdiUndo,
  mdiCancel,
  mdiClose,
  mdiSendClockOutline,
  mdiCheckAll,
  mdiDownload,
  mdiChevronLeft,
  mdiCloseCircleOutline,
  mdiChevronRight,
  mdiContentSave,
  mdiCheck,
  mdiDotsHorizontal,
  mdiDotsHorizontalCircleOutline,
  mdiDotsVertical
} from '@mdi/js';
import OrderStatus from '@/shared/types/OrderStatus';
import { userHasRoleWithName } from '@/shared/services/rbac/rbac.service';
import RoleType from '@/shared/types/RoleType';
import OrderApprovalHistoryDialog from '../components/OrderApprovalHistoryDialog.vue';
import cloneDeep from 'lodash.clonedeep';

const Mode = {
  BUILD: 'build',
  DISPLAY: 'display',
  DECISION: 'decision'
};

export default {
  name: 'OrderPage',
  components: {
    ContentLayout,
    CancelOrderDialog: () => import('../components/CancelOrderDialog.vue'),
    CloseOrderDialog: () => import('../components/CloseOrderDialog.vue'),
    OrderApprovalHistoryDialog,
    CzIcon,
    CzButton
  },
  props: {
    id: {
      type: String,
      required: true
    },
    documentType: {
      type: String
    }
  },
  mixins: [typesMixin],
  created() {
    this.loadOrder();
  },
  computed: {
    ...mapFields('auth', ['account', 'accountId', 'currentUser']),
    ...mapFields('customer', [
      'currentOrdersTabId',
      'accountWorkflows',
      'catalogItems'
    ]),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: 'customer'
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('customer.ordersPageTitle'),
          disabled: false,
          exact: true,
          to: {
            name: 'customer-orders'
          }
        },
        {
          text:
            this.id === 'new'
              ? this.$t('customer.createNewOrder')
              : this.$t('customer.orders.orderTitle', [
                  this.order?.orderNumber
                ]),

          disabled: true,
          exact: true
        }
      ];
    },
    isNewOrder() {
      return [OrderStatus.NEW, OrderStatus.NEW_PREDICTED].includes(
        this.order?.status
      );
    },
    mode() {
      const currentUserIsPurchaser = userHasRoleWithName(
        this.currentUser,
        RoleType.CUSTOMER_PURCHASER
      );
      // if order is new and the current user id is the one who created this order
      // then we move to the build mode
      if (
        this.isNewOrder &&
        currentUserIsPurchaser &&
        !this.order?.createdByUserId
      ) {
        return Mode.BUILD;
      } else if (
        this.isNewOrder ||
        (this.order?.status === OrderStatus.RETURNED &&
          this.order?.createdByUserId === this.currentUser._id)
      ) {
        return Mode.BUILD;
      } else if (
        this.order?.status === OrderStatus.PENDING_APPROVAL &&
        this.order?.currentApproverUserId === this.currentUser._id
      ) {
        return Mode.DECISION;
      }

      return Mode.DISPLAY;
    },
    canCancelOrder() {
      if (!this.order) {
        return false;
      }
      return (
        userHasRoleWithName(this.currentUser, RoleType.CUSTOMER_PURCHASER) &&
        this.order?.status !== OrderStatus.CANCELLED &&
        this.order?.status !== OrderStatus.CLOSED &&
        this.order?.status !== OrderStatus.REJECTED &&
        this.id !== 'new'
      );
    },
    canCloseOrder() {
      if (!this.order) {
        return false;
      }
      return (
        userHasRoleWithName(this.currentUser, RoleType.CUSTOMER_PURCHASER) &&
        this.order?.status !== OrderStatus.REJECTED &&
        (this.order?.status === OrderStatus.OPEN ||
          this.order?.status === OrderStatus.OPEN_PENDING_VENDOR_REG)
      );
    },
    isClosed() {
      return this.order?.status === OrderStatus.CLOSED;
    },
    isRejected() {
      return this.order?.status === OrderStatus.REJECTED;
    },
    orderSubmissionWorkflow() {
      return this.accountWorkflows.find(
        (item) => item.type === 'order-submission-workflow'
      );
    },
    orderSubmissionWorkflows() {
      return this.accountWorkflows.filter(
        (item) => item.type === 'order-submission-workflow'
      );
    },
    orderApprovalWorkflow() {
      return this.accountWorkflows.find(
        (item) => item.type === 'order-approval-workflow'
      );
    }
  },
  data() {
    return {
      Mode,
      mdiEmailFastOutline,
      mdiHistory,
      mdiContentSave,
      mdiInformationOutline,
      mdiSendClockOutline,
      mdiDotsHorizontal,
      mdiDotsVertical,
      mdiCloseCircleOutline,
      mdiDotsHorizontalCircleOutline,
      mdiCancel,
      mdiClose,
      mdiChevronLeft,
      mdiChevronRight,
      mdiDownload,
      mdiPencil,
      mdiCheck,
      mdiInformation,
      mdiUndo,
      mdiCheckAll,
      order: null,
      orderCopy: null,
      loading: false,
      orderApprovalHistoryDialog: {
        show: false,
        items: [],
        loading: true
      },
      cancelOrderDialog: {
        show: false,
        orderId: '',
        orderNumber: '',
        loading: false
      },
      closeOrderDialog: {
        show: false,
        order: null,
        loading: false
      },
      orderChangedPrompt: {
        show: false,
        title: this.$t('customer.orders.orderChangedPrompt.title'),
        message: this.$t('customer.orders.orderChangedPrompt.message'),
        doneButtonTitle: this.$t(
          'customer.orders.orderChangedPrompt.doneButtonTitle'
        ),
        state: 'info'
      },
      approvers: [],
      decisionDialog: {
        show: false,
        decisionType: '',
        objectType: 'order',
        loading: false
      }
    };
  },
  methods: {
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    ...mapActions('customer', ['loadAccountWorkflows', 'loadCatalogItems']),
    onOrderSaved(value) {
      this.order = structuredClone(value);
      this.orderCopy = structuredClone(value);
    },
    async loadOrder() {
      try {
        this.loading = true;
        await this.loadCatalogItems(1);
        await this.loadAccountWorkflows(this.accountId);
        if (this.id === 'new') {
          await this.initializeNewOrder();
        } else {
          const { data, total } = await getOrders({
            _id: this.id,
            accountId: this.accountId,
            $populate: ['notes']
          });

          if (total > 0) {
            this.order = data[0];
            if (this.order.issueDate) {
              this.order.issueDate = format(
                new Date(this.order.issueDate),
                'yyyy-MM-dd'
              );
            }

            if (this.order.orderItems?.length) {
              this.order.orderItems = this.order.orderItems.map((orderItem) => {
                return {
                  ...orderItem,
                  supplyDate: format(
                    new Date(orderItem.supplyDate),
                    'yyyy-MM-dd'
                  )
                };
              });
            }

            if (
              this.order.orderSubmissionWorkflowId &&
              !this.order.orderSubmissionWorkflowSteps?.length
            ) {
              this.onSelectedOrderSubmissionlWorkflowChange(
                this.order.orderSubmissionWorkflowId
              );
            } else if (!this.order.orderSubmissionWorkflowSteps?.length) {
              this.order.orderSubmissionWorkflowSteps = [];
            }

            if (
              this.order.orderApprovalWorkflowId &&
              !this.order.orderApprovalWorkflowSteps?.length
            ) {
              this.onSelectedOrderApprovalWorkflowChange(
                this.order.orderApprovalWorkflowId
              );
            } else if (!this.order.orderApprovalWorkflowSteps?.length) {
              this.order.orderApprovalWorkflowSteps = [];
            }
          } else {
            this.$router.replace({
              name: 'customer-orders'
            });
          }
        }
        this.handleOrderRoute();
        this.orderCopy = cloneDeep(this.order);
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.loading = false;
      }
    },
    handleOrderRoute() {
      if (this.mode === Mode.BUILD) {
        if (this.$route.name !== 'order-build') {
          this.$router.replace({
            name: 'order-build'
          });
        }
      } else if (this.mode === Mode.DECISION) {
        if (this.$route.name !== 'order-decision') {
          this.$router.replace({
            name: 'order-decision'
          });
        }
      } else {
        if (this.$route.name !== 'order-display') {
          this.$router.replace({
            name: 'order-display'
          });
        }
      }
    },
    async initializeNewOrder() {
      // if document type is empty
      // we cannot continue and we need to nav back
      if (!this.documentType) {
        this.$router.replace({
          name: 'customer-orders'
        });
      }

      const order = {
        title: '',
        orderNumber: '',
        description: '',
        account: this.account,
        accountId: this.accountId,
        createdByUserId: this.currentUser._id,
        vendorId: '',
        automated: false,
        creationType: 'manual',
        tags: [],
        status: 'new',
        documentType: this.documentType,
        issueDate: undefined,
        totalAmount: undefined,
        vatAmount: undefined,
        netAmount: undefined,
        currency: 'ILS',
        orderSubmissionWorkflowSteps: [],
        orderApprovalWorkflowSteps: [],
        accountManagementWorkflowSteps: [],
        orderItems: []
      };

      this.order = order;
    },
    onCancelOrder() {
      this.cancelOrderDialog.orderId = this.id;
      this.cancelOrderDialog.orderNumber = this.order.orderNumber;
      this.cancelOrderDialog.show = true;
    },
    onCloseOrder() {
      this.closeOrderDialog.order = this.order;
      this.closeOrderDialog.show = true;
    },
    async onShowOrderApprovalHistory() {
      try {
        this.orderApprovalHistoryDialog.loading = true;
        const { data } = await getOrderApprovalHistory({
          accountId: this.accountId,
          orderId: this.order._id
        });
        this.orderApprovalHistoryDialog.items = data;
        this.orderApprovalHistoryDialog.show = true;
      } finally {
        // this.orderApprovalHistoryDialog.loading = false;
      }
    },
    async closeOrder(closingReason) {
      try {
        this.closeOrderDialog.loading = true;
        await updateOrder(this.order._id, {
          status: OrderStatus.CLOSED,
          accountId: this.accountId,
          closingReason
        });
        this.showSuccessToastMessage(
          this.$t('customer.orders.orderClosedSuccess')
        );
        this.closeOrderDialog.show = false;
        this.$router.replace({
          name: 'customer-orders',
          params: {
            selectedTabId: 'closed-orders'
          }
        });
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.closeOrderDialog.loading = false;
      }
    },
    async cancelOrder(cancellationReason) {
      try {
        this.cancelOrderDialog.loading = true;
        // update the order
        await updateOrder(this.order._id, {
          status: OrderStatus.CANCELLED,
          accountId: this.accountId,
          cancellationReason
        });
        this.cancelOrderDialog.show = false;
        this.showSuccessToastMessage(
          this.$t('customer.orders.orderCancelledSuccess')
        );
        this.$router.replace({
          name: 'customer-orders',
          params: {
            selectedTabId: 'cancelled-orders'
          }
        });
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.cancelOrderDialog.loading = false;
      }
    },
    onSelectedOrderApprovalWorkflowChange(value) {
      this.order.orderApprovalWorkflowSteps = [];
      if (!value) {
        return;
      }

      const orderApprovalWorkflow = this.accountWorkflows.find(
        (item) => item._id === value
      );

      if (!orderApprovalWorkflow) {
        return;
      }

      for (const step of orderApprovalWorkflow.steps) {
        this.order.orderApprovalWorkflowSteps.push({
          _id: step._id,
          approverUserId: '',
          accountWorkflowStepId: step._id,
          orderId: this.order._id,
          stepNumber: step.stepNumber,
          approverRole: step.approverRole,
          approverRoleText: step.approverRoleText,
          stepTitle: step.stepTitle,
          stepType: step.stepType,
          stepTypeText: step.stepTypeText,
          workflowId: step.workflowId,
          accountId: step.accountId
        });
      }
    },
    onSelectedOrderSubmissionlWorkflowChange(value) {
      this.order.orderSubmissionWorkflowSteps = [];
      if (!value) {
        return;
      }

      const orderSubmissionWorkflow = this.accountWorkflows.find(
        (item) => item._id === value
      );

      if (!orderSubmissionWorkflow) {
        return;
      }

      for (const step of orderSubmissionWorkflow.steps) {
        this.order.orderSubmissionWorkflowSteps.push({
          _id: step._id,
          approverUserId: '',
          accountWorkflowStepId: step._id,
          orderId: this.order._id,
          stepNumber: step.stepNumber,
          // accountWorkflowStep: step,
          approverRole: step.approverRole,
          approverRoleText: step.approverRoleText,
          stepTitle: step.stepTitle,
          stepType: step.stepType,
          stepTypeText: step.stepTypeText,
          workflowId: step.workflowId,
          accountId: step.accountId
        });
      }

      const currentUserIsPurchaser = userHasRoleWithName(
        this.currentUser,
        RoleType.CUSTOMER_PURCHASER
      );

      if (currentUserIsPurchaser) {
        const purchaserStep = this.order.orderSubmissionWorkflowSteps.find(
          (item) => item.approverRole === RoleType.CUSTOMER_PURCHASER
        );

        if (purchaserStep) {
          purchaserStep.approverUserId = this.currentUser._id;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container-height {
  // max-height: calc(100vh - 400px);
  overflow-y: auto;
}

.doc-height {
  overflow: auto;
  // aspect-ratio: 1.25 / 1.414 !important;
  // scale: 1.2;
}
</style>
